import { api, apiAFD } from "../../config/api";
import { toFormData } from "../../helpers/form";

const basePath = "arquivoafd";

/**
 * Get all
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const getAll = (options) => {
	return api.get(basePath, options);
};

/**
 * Upload AFD archive
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const upload = (options) => {
	return api.post(basePath, toFormData(options));
};